import dayjs, { Dayjs } from 'dayjs';
import cx from 'classnames';
import './TeamGantishMonthSlices.less';
import { atLeastOne } from '../../../../../../../services/error-parser/helpers';
import { gql } from '@apollo/client';
import { TeamGantishMonthSlices__MilestoneFragment } from '../../../../../../../generated/graphql';
import { MilestoneIndicator } from '../../../../../../../components/initiatives/Gantish/components/GantishChart/MonthSlices/MilestoneIndicator';

interface Props {
  startOfMonth: Dayjs;
  initiativeDates: {
    startAt: Dayjs;
    endAt: Dayjs;
  } | null;
  milestones: TeamGantishMonthSlices__MilestoneFragment[];
  window: { start: Dayjs; end: Dayjs };
}

const NUM_SLICES = 4;

export const TeamGantishMonthSlices = ({
  startOfMonth,
  initiativeDates,
  milestones,
  window,
}: Props) => {
  const daysInMonth = startOfMonth.daysInMonth();
  const daysInSlice = Math.floor(daysInMonth / NUM_SLICES);

  const monthSlices = Array.from({ length: NUM_SLICES }).map((_, i) => {
    const start = startOfMonth.add(i * daysInSlice, 'days');
    let end = startOfMonth.add((i + 1) * daysInSlice - 1, 'days');

    if (i === NUM_SLICES - 1) {
      end = startOfMonth.endOf('month');
    }

    return { start, end };
  });

  const initStart = initiativeDates?.startAt;
  const initEnd = initiativeDates?.endAt;

  const monthSlicesWithInitInfo = monthSlices.map((slice) => {
    const startsBeforeWindow =
      (initStart == null || initStart.isBefore(window.start)) &&
      slice.start.isSame(window.start) &&
      (initEnd == null || initEnd.isAfter(window.start));

    const endsAfterWindow =
      (initEnd == null || initEnd.isAfter(window.end)) &&
      slice.end.isSame(window.end) &&
      (initStart == null || initStart.isBefore(window.end));

    const isStarting =
      initStart != null &&
      initStart.isSameOrAfter(slice.start) &&
      initStart.isSameOrBefore(slice.end);

    const isEnding =
      initEnd != null &&
      initEnd.isSameOrAfter(slice.start) &&
      initEnd.isSameOrBefore(slice.end);

    const hasStarted =
      initStart == null || initStart.isSameOrBefore(slice.start);

    const hasEnded = initEnd != null && initEnd.isBefore(slice.start);

    const isOngoing = !isStarting && !isEnding && hasStarted && !hasEnded;

    const milestonesToRender = milestones.filter((m) => {
      const milestoneDate = dayjs(m.deadlineAt);
      return milestoneDate.isBetween(slice.start, slice.end, 'day', '[]');
    });

    return {
      ...slice,
      isStarting,
      isEnding,
      isOngoing,
      startsBeforeWindow,
      endsAfterWindow,
      milestonesToRender,
    };
  });

  return (
    <>
      {monthSlicesWithInitInfo.map((slice, i) => (
        <div
          className={cx('TeamGantishMonthSlices__slice', {
            'TeamGantishMonthSlices__slice--starting': slice.isStarting,
            'TeamGantishMonthSlices__slice--ending': slice.isEnding,
            'TeamGantishMonthSlices__slice--ongoing': slice.isOngoing,
            'TeamGantishMonthSlices__slice--startsBeforeWindow':
              slice.startsBeforeWindow,
            'TeamGantishMonthSlices__slice--endsAfterWindow':
              slice.endsAfterWindow,
          })}
          style={{
            ['--num-slices' as string]: monthSlices.length,
          }}
          key={i}
        >
          {atLeastOne(slice.milestonesToRender) && (
            <MilestoneIndicator milestones={slice.milestonesToRender} />
          )}
          {slice.startsBeforeWindow && <Fnutt />}
          {slice.endsAfterWindow && <Fnutt isEnd />}
        </div>
      ))}
    </>
  );
};

const Fnutt = (props: { isEnd?: boolean }) => {
  const strokeWidth = 2;

  return (
    <svg
      height={`calc(100% + ${strokeWidth * 2}px)`}
      style={{
        position: 'absolute',
        [props.isEnd ? 'right' : 'left']: -11,
        top: -strokeWidth,
        transform: `scaleX(${props.isEnd ? -1 : 1})`,
      }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11 25"
    >
      <path
        className="MonthSlices__fnuttBg"
        d="M1 24h10V0H1l2.038 4.835a3 3 0 0 1 0 2.33l-1.502 3.563a3 3 0 0 0 .081 2.507l1.712 3.423a3 3 0 0 1 0 2.684L1 24Z"
      />
      <path
        className="MonthSlices__fnuttStroke"
        d="M11 .5H1l2.038 4.835a3 3 0 0 1 0 2.33l-1.502 3.563a3 3 0 0 0 .081 2.507l1.712 3.423a3 3 0 0 1 0 2.684L1 24.5h10"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAM_GANTISH_MONTH_SLICES__MILESTONES = gql`
  fragment TeamGantishMonthSlices__Milestone on MilestoneWithLinks {
    id
    deadlineAt
    ...MilestoneIndicator__Milestone
  }
`;
