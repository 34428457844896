import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import './MitemFilter.less';
import { gql, useSuspenseQuery } from '@apollo/client';
import { MilestoneFiltersDocument } from '../../../../../../generated/graphql';

interface Props {
  value: string;
  onChange: (milestoneId: string) => void;
  teamId: string;
}

export const MilestoneFilter = ({ teamId, value, onChange }: Props) => {
  const { data } = useSuspenseQuery(MilestoneFiltersDocument, {
    variables: {
      teamId,
    },
  });
  const { t } = useTranslation();
  const { Option } = Select;

  return (
    <div
      className="MitemFilters__filterContainer mr--s"
      data-intercom-target="Sprint View Table Milestone Filter"
    >
      <h5>{t('common.milestoneTitle')}</h5>
      <Select
        defaultValue={value}
        onChange={onChange}
        className="MitemFilter__Select"
      >
        <Option value="ALL" className="MitemFilters__divider">
          {t('MilestoneFilter.allMilestones')}
        </Option>
        {data.milestonesWithLinksForTeam.milestones?.map((o) => (
          <Option key={o.id} value={o.id}>
            {o.name}
          </Option>
        ))}
      </Select>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_FILTER_QUERY = gql`
  query milestoneFilters($teamId: ID!) {
    milestonesWithLinksForTeam(teamId: $teamId) {
      milestones {
        id
        ...MitemPlaningBoard_Milestone
      }
    }
  }
`;
