import { Form, Modal } from 'antd';
import { EditMilestoneSkeleton } from './EditMilestone.skeleton';
import { ErrorBoundary } from 'react-error-boundary';
import { Suspense } from 'react';
import { EditMilestone } from './EditMilestone';
import { ErrorPage } from '../../../../../../../../../../components/ErrorPage';
import { gql, useMutation } from '@apollo/client';
import { showNotification } from '../../../../../../../../../../services/fetchNotificationProperties';
import { useTranslation } from 'react-i18next';
import { EditMilestoneModalMutationDocument } from '../../../../../../../../../../generated/graphql';
import { utcTimeFormat } from '../../../../../../../../../../services/dateFormats';
import { howweErrorParser } from '../../../../../../../../../../services/howweErrorParser';
import { editInitiativeFormSchema } from '../../../../createMilestoneButton/components/MilestoneForm';

interface Props {
  milestoneId: string | undefined;
  open: boolean;
  onClose: () => void;
}

export const EditMilestoneModal = ({ milestoneId, open, onClose }: Props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [editInitiative, { loading }] = useMutation(
    EditMilestoneModalMutationDocument
  );

  const handleSubmit = () => {
    if (!milestoneId) throw new Error('Milestone ID is required');
    form.validateFields().then((unsafeValues) => {
      const values = editInitiativeFormSchema.parse(unsafeValues);

      const { rev, description, name, assignedTo, deadlineAt } = values;

      editInitiative({
        variables: {
          milestoneId,
          milestone: {
            rev,
            description,
            assignedTo: [
              {
                id: assignedTo,
              },
            ],
            deadlineAt: utcTimeFormat(deadlineAt),
            name: name,
          },
        },
        onCompleted: (data) => {
          showNotification('success', {
            message: (
              <strong>
                {t('common.initiative.editSuccess', {
                  name: data.updateMilestoneWithLinks.name,
                })}
              </strong>
            ),
          });
          onClose?.();
        },
        onError: (e) => {
          const howweErrors = howweErrorParser(e);

          showNotification('error', {
            message: (
              <strong>
                <ul>
                  {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
                </ul>
              </strong>
            ),
          });
        },
      });
    });
  };

  return (
    <Modal
      open={open}
      destroyOnClose
      onClose={onClose}
      onCancel={onClose}
      onOk={handleSubmit}
      okButtonProps={{ loading }}
      okText={t('common.save')}
      maskClosable={false}
    >
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <Suspense fallback={<EditMilestone.Skeleton />}>
          {milestoneId && (
            <EditMilestone milestoneId={milestoneId} form={form} />
          )}
        </Suspense>
      </ErrorBoundary>
    </Modal>
  );
};

EditMilestone.Skeleton = EditMilestoneSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EDIT_MILESTONE = gql`
  mutation EditMilestoneModalMutation(
    $milestoneId: ID!
    $milestone: MilestoneUpdateInput!
  ) {
    updateMilestoneWithLinks(milestoneId: $milestoneId, milestone: $milestone) {
      id
      name
      description
      domainId {
        itemId
      }
    }
  }
`;
