import { DatePicker, Form, FormInstance, Input, Select } from 'antd';

import { gql } from '@apollo/client';
import {
  MilestoneForm_InitiativeTagFragment,
  MilestoneForm__MilestoneFragment,
} from '../../../../../../../../generated/graphql';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';
import { InitiativeTag } from '../../../../../../../../components/initiative/InitiativeTag';
import { AlignmentIcon } from '../../../../../../../../components/initiatives/InitiativeForm/Icons/AlignmentIcon';
import { EndingPointIcon } from '../../../../../../../../components/initiatives/InitiativeForm/Icons/EndingPointIcon';
import { PersonSearchIcon } from '../../../../../../../../components/initiatives/InitiativeForm/Icons/PersonSearchIcon';
import { TextAreaIconIcon } from '../../../../../../../../components/initiatives/InitiativeForm/Icons/TextAreaIcon';
import { TitleIcon } from '../../../../../../../../components/initiatives/InitiativeForm/Icons/TitleIcon';
import { InitiativeAdminSelector } from '../../../../../../../../components/initiatives/InitiativeForm/initiativeAdminSelector/InitiativeAdminSelector';
import {
  Icons,
  InitiativeIcon,
} from '../../../../../../initiatives_old/initiativesPageV1/InitiativeIcons';
import { Suspense } from 'react';
import { z } from 'zod';
import { zDayjs } from '../../../../../../../../services/zodCustomTypes';

export type MilestoneInitialValues = Partial<MilestoneForm__MilestoneFragment>;

type InitialValues = {
  name?: string;
  assignedTo?: {
    id: string;
    data: {
      id: string;
      domainId: {
        itemId: string;
      };
      name: string | null;
      displayName: string | null;
      email: string;
      archived: boolean;
      initials: string;
    };
  }[];
  description?: string | null;
  deadlineAt?: string;
};

interface Props {
  form: FormInstance<any>;
  initialValue?: InitialValues;
  tag?: MilestoneForm_InitiativeTagFragment;
  loading?: boolean;
}

export const MilestoneForm = ({ loading, form, tag, initialValue }: Props) => {
  const { t } = useTranslation();
  const formInitialValues = initialValue
    ? {
        ...initialValue,
        assignedTo: initialValue?.assignedTo?.[0]?.id,
        deadlineAt: initialValue.deadlineAt && dayjs(initialValue.deadlineAt),
      }
    : undefined;

  return (
    <Form
      autoComplete="off"
      disabled={loading}
      form={form}
      layout="vertical"
      requiredMark={'optional'}
      initialValues={formInitialValues}
      clearOnDestroy
    >
      <div className="flx">
        <Form.Item name="rev" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <TitleIcon className="mr MilestoneForm__formIcon--singleField" />
        <Form.Item
          name="name"
          className="flx--1"
          label={t('common.milestone.title')}
          rules={[
            {
              required: true,
            },
            {
              min: 3,
              max: 51,
            },
          ]}
        >
          <Input type="text" className="bold" style={{ fontSize: '18px' }} />
        </Form.Item>
      </div>
      <div className="flx">
        <TextAreaIconIcon className="mr MilestoneForm__formIcon--singleField" />
        <Form.Item
          name="description"
          className="flx--1"
          label={t('common.milestone.description')}
          rules={[
            {
              required: true,
            },
            {
              min: 3,
              max: 500,
            },
          ]}
        >
          <TextArea rows={4} showCount maxLength={500} />
        </Form.Item>
      </div>
      <div className="flx">
        <PersonSearchIcon className="mr MilestoneForm__formIcon--singleField" />
        <Suspense
          fallback={
            <Form.Item className="flx--1 " label={t('common.milestone.owner')}>
              <Select loading />
            </Form.Item>
          }
        >
          <Form.Item
            name="assignedTo"
            className="flx--1 "
            label={t('common.milestone.owner')}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InitiativeAdminSelector
              initialOwner={initialValue?.assignedTo?.[0].data}
            />
          </Form.Item>
        </Suspense>
      </div>
      <div className="flx">
        <AlignmentIcon className="mr MilestoneForm__formIcon--singleField" />
        <Form.Item
          className="flx--1 mr--m"
          label={t('common.milestone.supports')}
          required
        >
          {/* required is set only to hide '(optional) label */}
          {tag && (
            <InitiativeTag
              title={tag.title}
              borderColor={tag.colorCode}
              icon={Icons[tag.iconId as InitiativeIcon]}
            />
          )}
        </Form.Item>
      </div>
      <div className="flx ">
        <EndingPointIcon className="mr MilestoneForm__formIcon--singleField" />
        <Form.Item
          className="flx--1"
          label={t('common.milestone.deadline')}
          name="deadlineAt"
          required
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker picker="date" className="fullWidth" />
        </Form.Item>
      </div>
    </Form>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_FORM__INITIATIVE_TAG = gql`
  fragment MilestoneForm_InitiativeTag on InitiativeTag {
    title
    iconId
    colorCode
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_FORM__MILESTONE = gql`
  fragment MilestoneForm__Milestone on MilestoneWithLinks {
    id
    name
    deadlineAt
    description
    rev
    assignedTo {
      id
      data {
        name
        displayName
        email
        ...StrategicAdminSelector__User2
      }
    }
  }
`;

const sharedSchema = z.object({
  name: z.string(),
  assignedTo: z.string(),
  description: z.string(),
  deadlineAt: zDayjs,
});

export const createMilestoneFormSchema = sharedSchema.extend({});

export const editInitiativeFormSchema = createMilestoneFormSchema.extend({
  rev: z.string(),
});
