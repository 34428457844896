import { gql, useSuspenseQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { GetInitiativeMilestoneDetailsDocument } from '../../../../../../../../../../generated/graphql';
import { DisplayDate } from '../../../../../../../../../../components/DisplayDate';
import { DisplayName } from '../../../../../../../../../../components/DisplayName';
import { InitiativeTag } from '../../../../../../../../../../components/initiative/InitiativeTag';

import { UserAvatar } from '../../../../../../../../../../components/UserAvatar';
import { milestoneStatusColor } from '../../../../../../../../../componentLibrary/Colors';
import { CheckIcon } from '../../../../../../../../../../icons/overviewIcons/CheckIcon';
import { GroupsIcon } from '../../../../../../../../../../icons/GroupsIcon';
import { PersonShieldIcon } from '../../../../../../../../../../icons/PersonShield';
import { AlignmentIcon } from '../../../../../../../../../../components/initiatives/InitiativeForm/Icons/AlignmentIcon';
import { EndingPointIcon } from '../../../../../../../../../../components/initiatives/InitiativeForm/Icons/EndingPointIcon';
import { TextAreaIconIcon } from '../../../../../../../../../../components/initiatives/InitiativeForm/Icons/TextAreaIcon';
import { MilestoneDetailsSkeleton } from './MilestoneDetails.skeleton';
import { MilestoneDetailsError } from './MilestoneDetailsError';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';

interface Props {
  milestoneId: string;
}

export const MilestoneDetails = ({ milestoneId }: Props) => {
  const { data } = useSuspenseQuery(GetInitiativeMilestoneDetailsDocument, {
    variables: { milestoneId },
  });

  const { t } = useTranslation();
  const milestone = data.milestoneWithLinks;

  const supportedInitiatives = milestone.metadata.supportsInitiatives;

  return (
    <div>
      <div className="flx mb--xl bold">
        <Tooltip title={t('common.milestone.title')}>
          <CheckIcon className="mr--l txt--secondary font-size--lg" />
        </Tooltip>
        {milestone.name}
      </div>
      <div className="flx mb--xl">
        <Tooltip title={t('common.milestone.description')}>
          <TextAreaIconIcon className="mr--l txt--secondary font-size--lg" />
        </Tooltip>
        {milestone.description}
      </div>
      <div className="flx mb--xl">
        <Tooltip title={t('common.milestone.deadline')}>
          <EndingPointIcon className="mr--l txt--secondary font-size--lg" />
        </Tooltip>
        <DisplayDate date={milestone.deadlineAt} />
        <span
          className="ml bold"
          style={{ color: milestoneStatusColor[milestone.metadata.status] }}
        >
          {t(
            `common.milestone.status.${milestone.metadata.status.toLowerCase()}`
          )}
        </span>
      </div>
      <div className="flx mb--xl">
        <Tooltip title={t('common.milestone.owner')}>
          <PersonShieldIcon className="mr--l txt--secondary font-size--lg" />
        </Tooltip>
        {milestone.assignedTo.map((assignee) => (
          <span key={assignee.id}>
            <span className="mr--s">
              <UserAvatar user={assignee.data} size="small" />
            </span>
            <DisplayName user={assignee.data} />
          </span>
        ))}
      </div>
      <div className="flx mb--xl">
        <Tooltip title={t('common.milestone.supports')}>
          <AlignmentIcon className="mr--l txt--secondary font-size--lg" />
        </Tooltip>

        {supportedInitiatives.map((supportsInitiativ) => (
          <Link
            key={supportsInitiativ.data.domainId.itemId}
            to={`/company/initiatives/${supportsInitiativ.data.domainId.itemId}`}
          >
            <InitiativeTag
              key={supportsInitiativ.data.tag.title}
              tag={supportsInitiativ.data.tag}
            />
          </Link>
        ))}
      </div>
      <div className="flx mb--xl">
        <Tooltip title={t('common.milestone.contributingTeams')}>
          <GroupsIcon className="mr--l txt--secondary font-size--lg" />
        </Tooltip>
        {milestone.teamLinks.map((tl) => tl.team.name).join(', ')}
      </div>
    </div>
  );
};

MilestoneDetails.Skeleton = MilestoneDetailsSkeleton;
MilestoneDetails.Error = MilestoneDetailsError;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SUPPORTED_INITIATIVE_FRAGMENT = gql`
  fragment MilestoneDetails__SupportedInitiative on InitiativeLink {
    id
    domainId {
      itemId
    }
    data {
      id
      domainId {
        itemId
      }
      name
      tag {
        colorCode
        iconId
        title
      }
    }
  }
`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_DETAILS_MODAL_CONTENT_QUERY = gql`
  query getInitiativeMilestoneDetails($tenantId: ID, $milestoneId: ID!) {
    milestoneWithLinks(tenantId: $tenantId, milestoneId: $milestoneId) {
      id
      domainId {
        itemId
        tenantId
      }
      name
      description
      deadlineAt
      assignedTo {
        id
        data {
          id
          email
          name
          displayName
          initials
        }
      }
      teamLinks {
        id
        team {
          id
          name
        }
      }
      metadata {
        status
        archived
        completedAt
        supportsInitiatives {
          ...MilestoneDetails__SupportedInitiative
        }
      }
    }
  }
`;
