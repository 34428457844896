import { Radio, RadioChangeEvent, Spin } from 'antd';
import { SkaCard } from '../SkaCard';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getMigData } from '../../../../../../utils/migCalculations';
import { AllocationAndProgressTable } from './components/AllocationAndProgressTable';
import { useTeamInitiativesLazy } from '../../../../../../../../../hooks/useTeamInitiatives';
import { getInitiativeData } from '../../../../../../utils/initiativeCalculations';
import { useGetTags } from '../../../../../../../../../hooks/useGetTags';
import {
  Action,
  AllocationAndProgressCardSkaTimeStatusFragment,
  AllocationAndProgressCard_MigV2Fragment,
  AllocationAndProgressInitiativeQueryDocument,
  GetTeamMigV2ForAllocationAndProgressCardDocument,
  MigStatus,
} from '../../../../../../../../../generated/graphql';
import { getTeamTagsData } from '../../../../../../utils/teamTagCalculations';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import './AllocationAndProgressCard.less';
import { useTenantDetails } from '../../../../../../../../../hooks/useTenantDetails';
import { RecursiveOmit } from '../../../../../../../../../services/typeHelpers';
import { useTeamPermissions } from '../../../../../../../../../usePermissions';
import { ga4 } from '../../../../../../../../../services/ga4/ga4';
import dayjs, { Dayjs } from 'dayjs';
import { match } from 'ts-pattern';

export enum allocationButtons {
  MIG = 'migButton',
  INITIATIVES = 'initiativesButton',
  TEAM_TAGS = 'teamTagsButton',
}

export type SprintStatus = 'Planned' | 'Active' | 'Finalized' | undefined;

let lastTabChange: Dayjs | null = null;

interface Props {
  sprintKeyActivities: AllocationAndProgressCardSkaTimeStatusFragment[];
  status?: SprintStatus;
  teamId: string;
}

export const AllocationAndProgressCard = ({
  sprintKeyActivities,
  status,
  teamId,
}: Props) => {
  const { t } = useTranslation();
  const [selectedButton, setSelectedButton] = useState(allocationButtons.MIG);

  const { isAllowed: isAllowedToReadInitiatives } = useTeamPermissions({
    requestedAction: [
      {
        resource: 'teamInitiative',
        action: [Action.READ],
      },
    ],
    teamId: teamId,
  });

  const {
    features: { teamInitiativesEnabled, tenantInitiativesEnabled },
  } = useTenantDetails();

  const [
    fetchTeamInitiatives,
    { data: teamInitiativesData, loading: loadingInitiatives },
  ] = useTeamInitiativesLazy();

  const [fetchTenantInitiatives, { data: tenantInitiativeData }] = useLazyQuery(
    AllocationAndProgressInitiativeQueryDocument
  );

  useEffect(() => {
    if (isAllowedToReadInitiatives) {
      if (teamInitiativesEnabled)
        fetchTeamInitiatives({ variables: { teamId } });
      else if (tenantInitiativesEnabled)
        fetchTenantInitiatives({ variables: { teamId } });
    }
  }, [
    isAllowedToReadInitiatives,
    fetchTeamInitiatives,
    teamId,
    teamInitiativesEnabled,
    tenantInitiativesEnabled,
    fetchTenantInitiatives,
  ]);

  const { tags, loading: loadingTeamTags } = useGetTags(teamId);
  const { data, loading: loadingTeamMigs } = useQuery(
    GetTeamMigV2ForAllocationAndProgressCardDocument,
    {
      variables: {
        teamId,
        statusFilters: [MigStatus.ACTIVE],
      },
    }
  );

  const initiatives = useMemo(
    () =>
      match({
        teamInitiativesEnabled,
        tenantInitiativesEnabled,
      })
        .with({ teamInitiativesEnabled: true }, () => {
          return (
            teamInitiativesData?.initiatives.initiatives?.map(
              (teamInitiative) => {
                return {
                  id: teamInitiative.id,
                  tag: teamInitiative.tag,
                  name: teamInitiative.name,
                  domainId: teamInitiative.domainId,
                  metadata: {
                    archived: teamInitiative.archived,
                    completedAt: teamInitiative.completed.value
                      ? teamInitiative.completed.setAt
                      : null,
                  },
                };
              }
            ) ?? []
          );
        })
        .with({ tenantInitiativesEnabled: true }, () => {
          return (
            tenantInitiativeData?.joinedTenantInitiativesForTeam.initiatives ??
            []
          );
        })
        .otherwise(() => {
          return [];
        }),
    [
      teamInitiativesData?.initiatives.initiatives,
      teamInitiativesEnabled,
      tenantInitiativeData?.joinedTenantInitiativesForTeam.initiatives,
      tenantInitiativesEnabled,
    ]
  );

  const activeInitiatives = useMemo(() => {
    return initiatives.filter((ti) => !ti.metadata.archived);
  }, [initiatives]);

  useEffect(() => {
    if (!loadingInitiatives && (activeInitiatives?.length ?? 0) > 0) {
      setSelectedButton(allocationButtons.INITIATIVES);
    }
  }, [loadingInitiatives, activeInitiatives]);

  const teamMigs = data?.teamMigs.migs;

  if (teamMigs == null) {
    return <Spin />;
  }

  const teamMigsWithoutArchived = teamMigs.filter(
    (mig) => mig.status !== MigStatus.ARCHIVED
  );

  const skasWithoutArchivedAlignedMigs = sprintKeyActivities.filter((ska) =>
    ska?.sprintKeyActivity?.supportedMigs?.some(
      (mig) => mig.status !== MigStatus.ARCHIVED
    )
  );

  const onChange = (e: RadioChangeEvent) => {
    setSelectedButton(e.target.value);
    ga4.gtag('event', 'change_allocation_and_progress_tab', {
      clicked: e.target.value,
      sinceLastTabChange:
        lastTabChange && dayjs().diff(lastTabChange, 'second'),
    });
    lastTabChange = dayjs();
  };

  const renderTable = (buttonChosen: string) => {
    switch (buttonChosen) {
      case allocationButtons.MIG:
        return (
          <Spin spinning={loadingTeamMigs}>
            <AllocationAndProgressTable
              data={getMigData(
                skasWithoutArchivedAlignedMigs,
                teamMigsWithoutArchived
              )}
              status={status}
            />
          </Spin>
        );
      case allocationButtons.INITIATIVES:
        return (
          <Spin spinning={loadingInitiatives}>
            <AllocationAndProgressTable
              data={getInitiativeData(
                sprintKeyActivities,
                activeInitiatives,
                tenantInitiativeData?.milestonesWithLinksForTeam.milestones ??
                  []
              )}
              status={status}
            />
          </Spin>
        );
      case allocationButtons.TEAM_TAGS:
        return (
          <Spin spinning={loadingTeamTags}>
            <AllocationAndProgressTable
              data={getTeamTagsData(sprintKeyActivities, tags)}
              status={status}
            />
          </Spin>
        );
      default:
        return (
          <Spin spinning={loadingTeamMigs}>
            <AllocationAndProgressTable
              data={getMigData(
                skasWithoutArchivedAlignedMigs,
                teamMigsWithoutArchived
              )}
              status={status}
            />
          </Spin>
        );
    }
  };

  return (
    <SkaCard>
      <div className="flx flx--jc-space-between">
        <h3
          className="AllocationAndProgressCard__header mb--xl"
          data-intercom-target="Allocation and progress header"
        >
          {t('AllocationAndProgressCard.header')}
        </h3>
        <Radio.Group
          className="mb--xl"
          onChange={onChange}
          value={selectedButton}
          defaultValue="migButton"
          data-intercom-target="Allocation and progress tabs"
        >
          <Radio.Button
            value={allocationButtons.MIG}
            data-intercom-target="MIG tab"
          >
            MIG's
          </Radio.Button>
          {(teamInitiativesEnabled || tenantInitiativesEnabled) && (
            <Radio.Button
              value={allocationButtons.INITIATIVES}
              disabled={loadingInitiatives || activeInitiatives?.length === 0}
              data-intercom-target="Initiatives tab"
            >
              {t('AllocationAndProgressCard.initiatives')}
            </Radio.Button>
          )}
          <Radio.Button
            value={allocationButtons.TEAM_TAGS}
            disabled={loadingTeamTags || tags?.length === 0}
            data-intercom-target="Team tags tab"
          >
            {t('AllocationAndProgressCard.teamTags')}
          </Radio.Button>
        </Radio.Group>
      </div>
      {renderTable(selectedButton)}
    </SkaCard>
  );
};

export type AllocationAndProgressCard_MigV2WithoutTypename = RecursiveOmit<
  AllocationAndProgressCard_MigV2Fragment,
  '__typename'
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TEAM_MIGS_FOR_ALLOCATION_AND_PROGRESS_CARD = gql`
  fragment AllocationAndProgressCard_MigV2 on MigV2 {
    id
    name
    status
    domainId {
      itemId
      teamId
    }
  }

  query getTeamMigV2ForAllocationAndProgressCard(
    $teamId: ID!
    $tenantId: ID
    $statusFilters: [MigStatus!]
  ) {
    teamMigs(
      teamId: $teamId
      tenantId: $tenantId
      statusFilters: $statusFilters
    ) {
      migs {
        ...AllocationAndProgressCard_MigV2
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ALLOCATION_AND_PROGRESS_CARD_FRAGMENT = gql`
  fragment AllocationAndProgressCardSkaTimeStatus on SkaTimeStatus {
    id
    status
    deadline
    teamId
    sprintKeyActivity {
      supportsInitiatives {
        id
      }
      supportsInitiatives2 {
        id
      }
      supportedMigs {
        id
        name
        status
        domainId {
          itemId
          teamId
        }
      }
      supportsMilestones {
        id
        domainId {
          itemId
        }
      }
      tags {
        id
        name
        backgroundColor
      }
    }
    owner {
      id
      name
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TENANT_INITIATIVE_QUERY = gql`
  query allocationAndProgressInitiativeQuery($tenantId: ID, $teamId: ID!) {
    joinedTenantInitiativesForTeam(tenantId: $tenantId, teamId: $teamId) {
      initiatives {
        id
        name
        domainId {
          itemId
        }
        tag {
          title
          colorCode
          iconId
        }
        metadata {
          completedAt
          archived
        }
      }
    }
    milestonesWithLinksForTeam(teamId: $teamId) {
      milestones {
        id
        name
        domainId {
          itemId
        }
        metadata {
          supportsInitiatives {
            id
            domainId {
              itemId
            }
            data {
              id
              domainId {
                itemId
              }
              name
            }
          }
          supportsMigs {
            id
            domainId {
              itemId
            }
            mig {
              id
              domainId {
                itemId
              }
              name
            }
          }
        }
      }
    }
  }
`;
