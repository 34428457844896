import { Select } from 'antd';
import { UserAvatar } from '../../../UserAvatar';
import { DisplayName } from '../../../DisplayName';
import { friendlyUsername } from '../../../../services/friendlyUsername';
import { gql, useQuery } from '@apollo/client';
import { InitiativeAdminSelector_PermittedUsersDocument } from '../../../../generated/graphql';

import { t } from 'i18next';

type UserForInitiativeSelector = {
  id: string;
  domainId: {
    itemId: string;
  };
  name: string | null;
  email: string;
  displayName: string | null;
  archived: boolean;
  initials: string;
};

interface Props {
  id?: string;
  value?: string | null;
  className?: string;
  initialOwner?: UserForInitiativeSelector;
  onChange?: (userId: string | undefined) => void;
}

export const InitiativeAdminSelector = ({
  id,
  value,
  className,
  initialOwner,
  onChange,
}: Props) => {
  const { data, loading } = useQuery(
    InitiativeAdminSelector_PermittedUsersDocument
  );

  let users =
    data?.listPermittedUsers.users?.map((u) => ({
      id: u.id,
      domainId: { itemId: u.domainId.itemId },
      name: u.name,
      email: u.email,
      displayName: u.displayName,
      archived: u.archived,
      initials: u.initials,
    })) ?? [];

  if (initialOwner) {
    const initialAdminAlreadyIncluded = data?.listPermittedUsers.users.some(
      (m) => m.id === initialOwner?.id
    );
    if (!initialAdminAlreadyIncluded) users = [...users, initialOwner];
  }

  return (
    <Select
      id={id}
      allowClear
      loading={loading}
      value={value}
      className={className}
      options={users.map((user) => ({
        value: user.id,
        label: (
          <div>
            <span className="mr--s">
              <UserAvatar user={user} size="small" />
            </span>

            {friendlyUsername(user)}
          </div>
        ),
        user,
      }))}
      onChange={(value) => {
        onChange?.(value);
      }}
      showSearch
      optionRender={(option) => {
        const user = option.data.user;
        return (
          <div className="flx">
            <span>
              <UserAvatar user={user} />
            </span>
            <div className="ml pr--s ellipsis">
              <DisplayName user={user} />
              <div className="font-size--sm txt--secondary ellipsis">
                {user.email}
              </div>
            </div>
          </div>
        );
      }}
      filterOption={(inputValue, option) => {
        if (!option) return false;
        return option.user.email
          .toLowerCase()
          .includes(inputValue.toLowerCase()) ||
          (option.user && option.user.name)
          ? option.user.name!.toLowerCase().includes(inputValue.toLowerCase())
          : false;
      }}
      placeholder="Search user"
      notFoundContent={<div>{t('common.noMatch')}</div>}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const STRATEGIC_PLAN_ADMIN_SELECTOR__USER2 = gql`
  fragment StrategicAdminSelector__User2 on User2 {
    id
    domainId {
      itemId
    }
    name
    email
    displayName
    archived
    initials
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const STRATEGIC_PLAN_ADMIN_SELECTOR__USER = gql`
  fragment StrategicAdminSelector__User on UserLight {
    id
    domainId {
      itemId
    }
    name
    email
    displayName
    archived
    initials
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const STRATEGIC_PLAN_ADMIN_SELECTOR = gql`
  query InitiativeAdminSelector_PermittedUsers {
    listPermittedUsers(
      permissionRequest: { action: UPDATE, resourceType: INITIATIVE }
    ) {
      users {
        id
        ...StrategicAdminSelector__User
      }
    }
  }
`;
