import dayjs from 'dayjs';
import { useState } from 'react';
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';

import './TeamGantishChart.less';
import { TeamGantishChartRow } from './GantishChart/TeamGantishChartRow';
import { TeamGantishMonthCell } from './GantishChart/TeamGantishMonthCell';
import { TeamGantishChartSkeleton } from './TeamGantishChart.skeleton';
import { gql } from '@apollo/client';
import { Btn } from '../../../../../../components/Button';
import {
  TeamGantishChart__InitiativeLightFragment,
  TeamGantishChart__MilestoneFragment,
} from '../../../../../../generated/graphql';

interface Props {
  initiativesWithMilestones: {
    initiative: TeamGantishChart__InitiativeLightFragment;
    milestones: TeamGantishChart__MilestoneFragment[];
  }[];
  unassociatedMilestones: TeamGantishChart__MilestoneFragment[];
}

const today = dayjs();

export const TeamGantishChart = ({
  initiativesWithMilestones,
  unassociatedMilestones,
}: Props) => {
  const [startDate, setStartDate] = useState(
    dayjs().subtract(3, 'month').startOf('month')
  );

  const months = Array.from({ length: 6 }, (_, i) => startDate.add(i, 'month'));

  const window = {
    start: months[0],
    end: months[months.length - 1].endOf('month'),
  };

  const handleBack = () => {
    setStartDate((prev) => prev.subtract(WINDOW_STEP_SIZE, 'month'));
  };

  const handleForward = () => {
    setStartDate((prev) => prev.add(WINDOW_STEP_SIZE, 'month'));
  };

  const monthProgress = (today.date() / today.daysInMonth()) * 100;

  const dates =
    unassociatedMilestones.length > 0
      ? {
          minDeadline: dayjs(
            Math.min(
              ...unassociatedMilestones.map((task) =>
                dayjs(task.deadlineAt).valueOf()
              )
            )
          ),
          maxDeadline: dayjs(
            Math.max(
              ...unassociatedMilestones.map((task) =>
                dayjs(task.deadlineAt).valueOf()
              )
            )
          ),
        }
      : null;

  return (
    <div
      className="TeamGantishChart"
      style={{ ['--month-percent' as any]: monthProgress + '%' }}
    >
      <div className="TeamGantishChart__row TeamGantishChart__row--header">
        <Btn
          className="TeamGantishChart__backBtn"
          icon={<CaretLeftFilled style={{ fontSize: ICON_SIZE }} />}
          type="link"
          onClick={handleBack}
        />

        {months.map((m, i) => (
          <TeamGantishMonthCell key={i} startOfMonth={m}>
            <span className="ml">
              {m.format(isJanuary(m) ? 'MMM YYYY' : 'MMM')}
            </span>
          </TeamGantishMonthCell>
        ))}

        <Btn
          className="TeamGantishChart__forwardBtn"
          icon={<CaretRightFilled style={{ fontSize: ICON_SIZE }} />}
          type="link"
          onClick={handleForward}
        />
      </div>

      <div id="subInitiatives">
        {initiativesWithMilestones?.map((initiativeWithMilestones) => (
          <TeamGantishChartRow
            initiativeDates={{
              startAt: dayjs(initiativeWithMilestones.initiative.startAt),
              endAt: dayjs(initiativeWithMilestones.initiative.endAt),
            }}
            milestones={initiativeWithMilestones.milestones}
            months={months}
            window={window}
            key={initiativeWithMilestones.initiative.id}
          />
        ))}
      </div>

      <div className="GantishChart__row GantishChart__row--spacer">
        {months.map((m, i) => (
          <TeamGantishMonthCell key={i} startOfMonth={m} />
        ))}
      </div>
      {dates && (
        <TeamGantishChartRow
          initiativeDates={{
            startAt: dates.minDeadline,
            endAt: dates.maxDeadline,
          }}
          milestones={unassociatedMilestones}
          months={months}
          window={window}
        />
      )}
    </div>
  );
};

const isJanuary = (date: dayjs.Dayjs) => date.month() === 0;

const ICON_SIZE = 11;
const WINDOW_STEP_SIZE = 1;

TeamGantishChart.Skeleton = TeamGantishChartSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAM_GANTISH_CHART__INITIATIVE_LIGHT = gql`
  fragment TeamGantishChart__InitiativeLight on InitiativeLight {
    id
    startAt
    endAt
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAM_GANTISH_CHART__MILESTONES = gql`
  fragment TeamGantishChart__Milestone on MilestoneWithLinks {
    id
    deadlineAt
    ...TeamGantishLegendRow__Milestone
    ...TeamGantishChartRow__Milestone
  }
`;
