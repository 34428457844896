import { Alert, Input, Radio, Space } from 'antd';
import { usePermissionForTeams } from '../../../../../../../hooks/usePermissionForTeams';
import { useMemo, useState } from 'react';
import {
  Action,
  SelectInitiativeTeam_AssignTeamToMilestoneMutationDocument,
  SelectInitiativeTeam_InitiativeQueryDocument,
  SelectInitiativeTeam_MilestoneQueryDocument,
  TeamLinkTypes,
  TeamResourceType,
} from '../../../../../../../generated/graphql';
import { gql, skipToken, useMutation, useSuspenseQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { getDifference } from '../../../../../../../services/arrayUtils';
import { Btn } from '../../../../../../../components/Button';
import { showNotification } from '../../../../../../../services/fetchNotificationProperties';
import { howweErrorParser } from '../../../../../../../services/howweErrorParser';
import { SelectInitiativeTeamSkeleton } from './SelectInitiativeTeam.skeleton';
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';

type MilestoneIdEntity = {
  id: string;
  domainId: {
    itemId: string;
    tenantId: string;
  };
};

interface Props {
  onConfirm: (teamId: string) => void;
  initiativeId: string;
  targetMilestone?: MilestoneIdEntity;
}

export const SelectInitiativeTeam = ({
  onConfirm,
  initiativeId,
  targetMilestone,
}: Props) => {
  const { t } = useTranslation();
  const [selectedTeam, setSelectedTeam] = useState<
    | {
        teamId: string;
        assignedTo: 'Initiative' | 'Milestone';
      }
    | undefined
  >();

  const [teamFilter, setTeamFilter] = useState('');

  const { data: teamLinkPermissions } = usePermissionForTeams(
    TeamResourceType.TEAM_LINKS,
    Action.UPDATE
  );

  // Get data about which teams the current user is allowed to create SKAs for
  const { data: skaPermissions } = usePermissionForTeams(
    TeamResourceType.SPRINT_KA,
    Action.CREATE
  );

  const teamsUserCanCreateSkasFor = useMemo(
    () =>
      skaPermissions?.permittedToPerformActionForTeam.permittedResources ?? [],
    [skaPermissions]
  );

  const teamsUserIsAllowedToLink =
    teamLinkPermissions?.permittedToPerformActionForTeam.permittedResources ??
    [];

  // Get data about teams assigned to the current initiative
  const { data: initData } = useSuspenseQuery(
    SelectInitiativeTeam_InitiativeQueryDocument,
    {
      variables: {
        initiativeId,
      },
      fetchPolicy: 'network-only',
    }
  );

  // Get data about teams assigned to the chosen milestone
  const { data: msData } = useSuspenseQuery(
    SelectInitiativeTeam_MilestoneQueryDocument,
    targetMilestone
      ? {
          variables: {
            milestoneId: targetMilestone.domainId.itemId,
          },
          fetchPolicy: 'network-only',
        }
      : skipToken
  );

  const teamsAssignedToMilestone =
    msData?.milestoneWithLinks?.teamLinks.map((tl) => tl.team) ?? [];

  const filteredTeamsAssignedToMilestone = teamsAssignedToMilestone.filter(
    (t) => t.name.toLowerCase().includes(teamFilter.toLowerCase())
  );

  const teamsAssignedToInitiative =
    initData?.teamsLinkedToInitiative.linkedTeams.map((lt) => lt.team);

  const teamsAssignedOnlyToInitiative = getDifference(
    teamsAssignedToInitiative,
    teamsAssignedToMilestone ?? [],
    (team) => team.id
  );

  const filteredTeamsAssignedToInitiative =
    teamsAssignedOnlyToInitiative.filter((t) =>
      t.name.toLowerCase().includes(teamFilter.toLowerCase())
    );

  const [assignMilestoneToTeam, { loading }] = useMutation(
    SelectInitiativeTeam_AssignTeamToMilestoneMutationDocument
  );

  const handleAssignToMilestone = (selectedMilestone: MilestoneIdEntity) => {
    if (!selectedTeam) return;
    assignMilestoneToTeam({
      variables: {
        teamId: selectedTeam.teamId,
        domainId: {
          tenantId: selectedMilestone.domainId.tenantId,
          itemId: selectedMilestone.domainId.itemId,
        },
        type: TeamLinkTypes.MILESTONE,
      },
      onCompleted: () => {
        showNotification('success', {
          message: (
            <span className="bold">
              {t('ManageMilestoneTeams.assignedSuccessfully')}
            </span>
          ),
        });
        onConfirm(selectedTeam.teamId);
      },
      onError: (error) => {
        const howweErrors = howweErrorParser(error);

        showNotification('error', {
          message: (
            <strong>
              <ul>
                {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
              </ul>
            </strong>
          ),
        });
      },
    });
  };

  return (
    <div>
      <h4
        className="bold mb--s"
        data-intercom-target="Sprint Key Activity Form Name Input"
      >
        {t('SelectInitiativeTeam.selectTeam')}
      </h4>

      <Input
        className="mb--l"
        value={teamFilter}
        onChange={(e) => setTeamFilter(e.target.value)}
        placeholder={t('SelectInitiativeTeam.filterPlaceholder')}
        prefix={<SearchOutlined className="txt--secondary" />}
        allowClear
      />

      {targetMilestone && (
        <>
          <h5 className="mb--s txt--secondary">
            {t('SelectInitiativeTeam.teamsAssignedToMilestone')}
          </h5>
          {teamsAssignedToMilestone.length === 0 && (
            <div className="ml--l">
              {t('SelectInitiativeTeam.noMilestoneTeams')}
            </div>
          )}
          <Radio.Group
            value={selectedTeam?.teamId}
            onChange={(e) =>
              setSelectedTeam({
                teamId: e.target.value,
                assignedTo: 'Milestone',
              })
            }
          >
            <Space direction="vertical">
              {filteredTeamsAssignedToMilestone.map((team) => (
                <Radio
                  key={team.id}
                  value={team.id}
                  disabled={
                    !teamsUserCanCreateSkasFor.some(
                      (prt) => team.id === prt.teamId
                    )
                  }
                >
                  {team.name}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </>
      )}

      <h5 className="mt--l mb--s txt--secondary">
        {t('SelectInitiativeTeam.teamsAssignedToInitiative')}
      </h5>
      {teamsAssignedOnlyToInitiative.length === 0 && (
        <div className="ml--l">
          {t('SelectInitiativeTeam.noInitiativeTeams')}
        </div>
      )}
      <Radio.Group
        value={selectedTeam?.teamId}
        onChange={(e) =>
          setSelectedTeam({ teamId: e.target.value, assignedTo: 'Initiative' })
        }
      >
        <Space direction="vertical">
          {filteredTeamsAssignedToInitiative.map((team) => (
            <Radio
              key={team.id}
              value={team.id}
              disabled={
                !teamsUserCanCreateSkasFor.some(
                  (prt) => team.id === prt.teamId
                ) ||
                !teamsUserIsAllowedToLink.some((prt) => team.id === prt.teamId)
              }
            >
              {team.name}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
      <div className="mt--xl mb--xxl">
        <Btn
          type="primary"
          loading={loading}
          onClick={() => {
            if (!selectedTeam) return;

            if (targetMilestone && selectedTeam.assignedTo !== 'Milestone') {
              handleAssignToMilestone(targetMilestone);
            } else {
              onConfirm(selectedTeam.teamId);
            }
          }}
          disabled={selectedTeam == null}
        >
          {t('SelectInitiativeTeam.confirmTeamSelection')}
        </Btn>
      </div>

      <Alert
        type="info"
        icon={<InfoCircleOutlined />}
        showIcon
        message={t('SelectInitiativeTeam.informationTitle')}
        description={t('SelectInitiativeTeam.permissionsRequirement')}
      />
    </div>
  );
};

SelectInitiativeTeam.skeleton = SelectInitiativeTeamSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MS_LINKS = gql`
  query SelectInitiativeTeam_MilestoneQuery($milestoneId: ID!) {
    milestoneWithLinks(milestoneId: $milestoneId) {
      id
      teamLinks {
        id
        team {
          id
          name
        }
      }
      metadata {
        completedAt
        status
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INIT_LINKS = gql`
  query SelectInitiativeTeam_InitiativeQuery($initiativeId: ID!) {
    teamsLinkedToInitiative(initiativeId: $initiativeId) {
      id
      linkedTeams {
        team {
          id
          name
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ASSIGN_MILESTONE_TO_TEAM = gql`
  mutation SelectInitiativeTeam_AssignTeamToMilestoneMutation(
    $tenantId: ID
    $teamId: ID!
    $domainId: TeamLinkDomainIdInput!
    $type: TeamLinkTypes!
  ) {
    createLinkToTeam(
      tenantId: $tenantId
      teamId: $teamId
      domainId: $domainId
      type: $type
    ) {
      id
    }
  }
`;
